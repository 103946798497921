import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";

const SesionLinks = (props) => {
  const { auth, profile } = props;
  // console.log(profile.rol);
  switch (profile.rol) {
    case "admin":
      return (
        <>
          <ul className="right">
            <li>
              <NavLink to="/">inicio</NavLink>
            </li>
            <li>
              <a href="/" onClick={props.signOut}>
                Cerrar Sesión
              </a>
            </li>
            <li>
              <NavLink to="/nuevoDocumento">Nuevo documento</NavLink>
            </li>
            {/* <li className="hide-on-small-only">
              <NavLink
                to="/registro"
                className="btn btn-floating cyan darken-2"
              >
                {props.profile.iniciales}
              </NavLink>
            </li> */}
          </ul>
        </>
      );
    case "proveedor":
      return (
        <>
          <ul className="right">
            <li>
              <NavLink to="/">inicio</NavLink>
            </li>
            <li>
              <NavLink to={"/" + profile.area + "/" + auth.uid}>Perfil</NavLink>
            </li>
            <li>
              <a href="/" onClick={props.signOut}>
                Cerrar Sesión
              </a>
            </li>
            {/* <li className="hide-on-small-only">
              <NavLink
                to="/registro"
                className="btn btn-floating cyan darken-2"
              >
                {props.profile.iniciales}
              </NavLink>
            </li> */}
          </ul>
        </>
      );
    case "proveedorCS":
      return (
        <>
          <ul className="right">
            <li>
              <NavLink to="/">inicio</NavLink>
            </li>
            <li>
              <NavLink to={"/calidadcs/" + auth.uid}>Calidad</NavLink>
            </li>
            <li>
              <NavLink to={"/planeacioncs/" + auth.uid}>Planeación</NavLink>
            </li>
            <li>
              <a href="/" onClick={props.signOut}>
                Cerrar Sesión
              </a>
            </li>
            {/* <li className="hide-on-small-only">
              <NavLink
                to="/registro"
                className="btn btn-floating cyan darken-2"
              >
                {props.profile.iniciales}
              </NavLink>
            </li> */}
          </ul>
        </>
      );
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SesionLinks);
