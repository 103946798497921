import React, { Component } from "react";
import { connect } from "react-redux";
// import { firestoreConnect } from "react-redux-firebase";
// import { compose } from "redux";
import { Redirect } from "react-router-dom";
import "./politicaDatos.css";

class politicaDatos extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/iniciarSesion" />;
    return (
      <>
        <section style={{ paddingTop: "5em" }} id="politicaDatos">
          <div className="container">
            <div>
              <h3 className="center">
                POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES
              </h3>
              <h4 className="center">HACEB WHIRLPOOL INDUSTRIAL S.A.S.</h4>
              <div className="tituloDocumento">
                <h3>
                  <b>CONTENIDO</b>
                </h3>
                <p>
                  <a className="indice" href="#identificacion">
                    I.
                  </a>
                  Identificación de la compañía, Introducción y Objetivo de la
                  Política
                </p>
                <p>
                  <a className="indice" href="#definiciones">
                    II.
                  </a>
                  Definiciones
                </p>
                <p>
                  <a className="indice" href="#principios">
                    III.
                  </a>
                  Principios para el Tratamiento de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#ambito">
                    IV.
                  </a>
                  Ámbito de aplicación
                </p>
                <p>
                  <a className="indice" href="#derechos">
                    V.
                  </a>
                  Derechos del Titular de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#sinAutorizacion">
                    VI.
                  </a>
                  Casos en los cuales la Compañía no requiere autorización para
                  el Tratamiento de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#deberesResponsable">
                    VII.
                  </a>
                  Deberes del Responsable del Tratamiento de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#deberesEncargado">
                    VIII.
                  </a>
                  Deberes del Encargado del Tratamiento de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#dataBases">
                    IX.
                  </a>
                  Clases de Bases de Datos y Finalidades del Tratamiento de
                  Datos Personales
                </p>
                <p>
                  <a className="indice" href="#procedimientos">
                    X.
                  </a>
                  Procedimientos para el Tratamiento de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#modificaciones">
                    XI.
                  </a>
                  Modificaciones a la Política
                </p>
                <p>
                  <a className="indice" href="#internacional">
                    XII.
                  </a>
                  Utilización y Transferencia Internacional de Datos Personales
                  e Información Personal por parte de la Compañía
                </p>
                <p>
                  <a className="indice" href="#mecanismos">
                    XIII.
                  </a>
                  Mecanismos dispuestos por Haceb Whirlpool Industrial S.A.S.
                  como Responsable del Tratamiento de Datos Personales
                </p>
                <p>
                  <a className="indice" href="#vigencia">
                    XIV.
                  </a>
                  Ley, Jurisdicción y Vigencia de la Política
                </p>
              </div>
            </div>
            <div className="tituloDocumento" id="identificacion">
              <h3>
                <b>
                  I. IDENTIFICACIÓN DE LA COMPAÑÍA, INTRODUCCIÓN Y OBJETIVO DE
                  LA POLÍTICA
                </b>
              </h3>
              <p>
                <b>NOMBRE DE LA COMPAÑÍA: </b>Haceb Whirlpool Industrial S.A.S.,
                que en adelante se denominará “HWI” o la “Compañía”, una
                compañía cuyo objeto social principal consiste en la Fabricación
                de aparatos de uso doméstico.
              </p>
              <p>
                <b>DIRECCIIÓN Y TELÉFONO: </b>La Compañía tiene su domicilio en
                el municipio de Copacabana, ubicada en la Carrera 48 Calle
                59-21. Teléfono (57-4) 5408470.
              </p>
              <p>
                <b>CORREO ELECTRÓNICO: </b>datospersonales@hacebwhirlpool.com
              </p>
            </div>
            <div className="tituloDocumento" id="definiciones">
              <h3>
                <b>II. DEFINICIONES</b>
              </h3>
              <p>
                Para efectos de la presente Política, se tendrán en cuenta las
                definiciones señaladas en la Ley 1581 de 2012 tal como se
                transcriben a continuación:
              </p>
              <p>
                <b>(a) Autorización: </b>Consentimiento previo, expreso e
                informado del Titular para llevar a cabo el Tratamiento de datos
                personales.
              </p>
              <p>
                <b>(b) Base de Datos: </b>Conjunto organizado de datos
                personales que sea objeto de Tratamiento.
              </p>
              <p>
                <b>(c) Dato Personal: </b>Cualquier información vinculada o que
                pueda asociarse a una o varias personas naturales determinadas o
                determinables.
              </p>
              <p>
                <b>(d) Dato Sensible: </b>Aquellos que afectan la intimidad del
                Titular o cuyo uso indebido puede generar su discriminación.
              </p>
              <p>
                <b>(e) Encargado del Tratamiento: </b>Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, realice el Tratamiento de datos personales por cuenta del
                Responsable del Tratamiento.
              </p>
              <p>
                <b>
                  (f) Políticas de Tratamiento en materia de Protección de Datos
                  Personales:{" "}
                </b>
                Se refiere al presente documento.
              </p>
              <p>
                <b>(g) Responsable del Tratamiento: </b>Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, decida sobre la base de datos y/o el Tratamiento de los
                datos.
              </p>
              <p>
                <b>(h) Titular: </b>Persona natural cuyos Datos Personales sean
                objeto de Tratamiento.
              </p>
              <p>
                <b>(i) Tratamiento:</b>Cualquier operación o conjunto de
                operaciones sobre datos personales, tales como la recolección,
                almacenamiento, uso, circulación o supresión.
              </p>
            </div>
            <div className="tituloDocumento" id="principios">
              <h3>
                <b>III. PRINCIPIOS PARA EL TRATAMIENTO DE DATOS PERSONALES</b>
              </h3>
              <p>
                De conformidad con el artículo 4 de la Ley 1581 de 2012, los
                principios que rigen el Tratamiento de los Datos Personales en
                HWI son:
              </p>
              <p>
                <b>(a) Acceso y circulación restringida: </b>HWI realizará el
                Tratamiento en sujeción a los límites que se derivan de la
                naturaleza de los datos personales que recolecta en sus Bases de
                Datos.
              </p>
              <p>
                <b>(b) Confidencialidad: </b>HWI garantiza la reserva de la
                información, inclusive después de finalizada su relación con
                alguna de las labores que comprende el Tratamiento, pudiendo
                sólo realizar suministro o comunicación de datos personales
                cuando ello corresponda al desarrollo de las actividades
                autorizadas en la Ley 1581 de 2012 y en los términos de la
                misma.
              </p>
              <p>
                <b>(c) Finalidad: </b>El Tratamiento realizado por HWI obedece a
                una finalidad legítima de acuerdo con la Constitución y la Ley,
                la cual es informada al Titular al momento de obtención de su
                autorización por los diferentes medios dispuestos para tal fin y
                que además se encuentra contenida en la presente Política.
              </p>
              <p>
                <b>(d) Legalidad: </b>El Tratamiento que realiza HWI se sujeta a
                las disposiciones de la Ley 1581 de 2012 y en las demás
                disposiciones que la desarrollen.
              </p>
              <p>
                <b>(e) Libertad: </b>HWI ejerce el Tratamiento cuando cuenta con
                el consentimiento, previo, expreso e informado del Titular o en
                aquellos casos en los que se hace necesario en virtud de la
                vinculación legal o contractual que existe con el Titular. Los
                datos personales no son obtenidos o divulgados sin previa
                autorización, o en ausencia de mandato legal o judicial que
                releve el consentimiento.
              </p>
              <p>
                <b>(f) Seguridad: </b>La información contenida en las Bases de
                Datos de HWI, se maneja con la implementación de las medidas
                técnicas, humanas y administrativas que se hacen necesarias para
                otorgar seguridad a los registros evitando su adulteración,
                pérdida, consulta, uso o acceso no autorizado o fraudulento.
              </p>
              <p>
                <b>(g) Transparencia: </b>HWI garantiza al Titular su derecho a
                obtener en cualquier momento y sin restricciones, información
                acerca de la existencia de datos que le conciernan.
              </p>
              <p>
                <b>(h) Veracidad o calidad: </b>HWI procura que la información
                sujeta a Tratamiento sea veraz, completa, exacta, actualizada,
                comprobable y comprensible y se abstiene de realizar el
                Tratamiento de datos parciales, incompletos, fraccionados o que
                induzcan a error.
              </p>
            </div>
            <div className="tituloDocumento" id="ambito">
              <h3>
                <b>IV. ÁMBITO DE APLICACIÓN:</b>
              </h3>
              <p>
                La presente política será aplicable a los datos personales
                registrados en cualquier base de datos de HWI cuyo titular sea
                una persona natural.
              </p>
            </div>
            <div className="tituloDocumento" id="derechos">
              <h3>
                <b>V. DERECHOS DEL TITULAR DE DATOS PERSONALES</b>
              </h3>
              <p>
                De conformidad con el artículo 8 de la Ley 1581 de 2012, el
                Titular de los Datos Personales tendrá los siguientes derechos:
              </p>
              <p>
                <b>(a) </b>Conocer, actualizar y rectificar sus datos personales
                frente a HWI o los Encargados del Tratamiento. Este derecho se
                podrá ejercer, entre otros frente a datos parciales, inexactos,
                incompletos, fraccionados, que induzcan a error, o aquellos cuyo
                Tratamiento esté expresamente prohibido o no haya sido
                autorizado.
              </p>
              <p>
                <b>(b) </b>Solicitar prueba de la autorización otorgada a HWI
                salvo cuando expresamente se exceptúe como requisito para el
                Tratamiento, de conformidad con lo previsto en el artículo 10 de
                la Ley 1581 de 2012.
              </p>
              <p>
                <b>(c) </b>Ser informado por HWI o el Encargado del Tratamiento,
                previa solicitud, respecto del uso que le ha dado a sus datos
                personales.
              </p>
              <p>
                <b>(d) </b>Presentar ante la Superintendencia de Industria y
                Comercio quejas por infracciones a lo dispuesto en la Ley 1581
                de 2012 y las demás normas que la modifiquen, adicionen o
                complementen.
              </p>
              <p>
                <b>(e) </b>Revocar la autorización y/o solicitar la supresión
                del Dato Personal cuando en el Tratamiento no se respeten los
                principios, derechos y garantías constitucionales y legales. La
                revocatoria y/o supresión procederá cuando la Superintendencia
                de Industria y Comercio haya determinado que en el Tratamiento
                el Responsable o Encargado han incurrido en conductas contrarias
                a la Ley 1581 de 2012 y a la Constitución.
              </p>
              <p>
                <b>(f) </b>Acceder en forma gratuita a sus datos personales que
                hayan sido objeto de Tratamiento.
              </p>
              <p>
                De conformidad con el artículo 20 del Decreto 1377 de 2013, los
                Derechos antes mencionados podrán ser ejercidos (I) Por el
                Titular, quien deberá acreditar su identidad en forma suficiente
                por los distintos medios que le ponga a disposición el
                Responsable; (II) Por sus causahabientes, quienes deberán
                acreditar tal calidad; (III) Por el representante y/o apoderado
                del Titular, previa acreditación de la representación o
                apoderamiento; y/o (IV) Por estipulación a favor de otro o para
                otro.
              </p>
              <p>
                Los derechos de los niños, niñas o adolescentes se ejercerán por
                las personas que estén facultadas para representarlos.
              </p>
            </div>
            <div className="tituloDocumento" id="sinAutorizacion">
              <h3>
                <b>
                  VI. CASOS EN LOS CUALES LA COMPAÑÍA NO REQUIERE AUTORIZACIÓN
                  PARA EL TRATAMIENTO DE DATOS PERSONALES
                </b>
              </h3>
              <p>
                La autorización del Titular no será necesaria cuando se trate
                de:
              </p>
              <p>
                <b>(a) </b>Información requerida por una entidad pública o
                administrativa en ejercicio de sus funciones legales o por orden
                judicial.
              </p>
              <p>
                <b>(b) </b>Datos de naturaleza pública, es decir, aquellos datos
                que no sean semiprivados, privados o sensibles y pueden estar
                contenidos, entre otros, en registros y documentos públicos,
                gacetas y boletines oficiales y sentencias judiciales.
              </p>
              <p>
                <b>(c) </b>Casos de urgencia médica o sanitaria.
              </p>
              <p>
                <b>(d) </b>Tratamiento de información autorizada por la ley para
                fines históricos, estadísticos o científicos.
              </p>
              <p>
                <b>(e) </b>Datos relacionados con el Registro Civil de las
                personas.
              </p>
            </div>
            <div className="tituloDocumento" id="deberesResponsable">
              <h3>
                <b>
                  VII. DEBERES DEL RESPONSABLE DEL TRATAMIENTO DE DATOS
                  PERSONALES
                </b>
              </h3>
              <p>
                De conformidad con el art. 17 de la Ley 1581 de 2012, el
                Responsable del Tratamiento tendrá los siguientes deberes:
              </p>
              <p>
                <b>(a) </b>Garantizar al Titular, en todo tiempo, el pleno y
                efectivo ejercicio del derecho de hábeas data.
              </p>
              <p>
                <b>(b) </b>Solicitar y conservar, en las condiciones previstas
                en la reglamentación aplicable, copia de la respectiva
                autorización otorgada por el Titular.
              </p>
              <p>
                <b>(c) </b>Informar debidamente al Titular sobre la finalidad de
                la recolección y los derechos que le asisten por virtud de la
                autorización otorgada.
              </p>
              <p>
                <b>(d) </b>Conservar la información bajo las condiciones de
                seguridad necesarias para impedir su adulteración, pérdida,
                consulta, uso o acceso no autorizado o fraudulento.
              </p>
              <p>
                <b>(e) </b>Garantizar que la información que se suministre al
                Encargado del Tratamiento sea veraz, completa, exacta,
                actualizada, comprobable y comprensible.
              </p>
              <p>
                <b>(f) </b>Actualizar la información, comunicando de forma
                oportuna al Encargado del Tratamiento, todas las novedades
                respecto de los datos que previamente le haya suministrado y
                adoptar las demás medidas necesarias para que la información
                suministrada a éste se mantenga actualizada.
              </p>
              <p>
                <b>(g) </b>Rectificar la información cuando sea incorrecta y
                comunicar lo pertinente al Encargado del Tratamiento.
              </p>
              <p>
                <b>(h) </b>Suministrar al Encargado del Tratamiento, según el
                caso, únicamente datos cuyo Tratamiento esté previamente
                autorizado de conformidad con lo previsto en la reglamentación
                aplicable.
              </p>
              <p>
                <b>(i) </b>Exigir al Encargado del Tratamiento en todo momento,
                el respeto a las condiciones de seguridad y privacidad de la
                información del Titular.
              </p>
              <p>
                <b>(j) </b>Tramitar las consultas y reclamos formulados en los
                términos señalados en la reglamentación aplicable.
              </p>
              <p>
                <b>(k) </b>Adoptar un manual interno de políticas y
                procedimientos para garantizar el adecuado cumplimiento de la
                reglamentación aplicable y en especial, para la atención de
                consultas y reclamos.
              </p>
              <p>
                <b>(l) </b>Informar al Encargado del Tratamiento cuando
                determinada información se encuentra en discusión por parte del
                Titular, una vez se haya presentado la reclamación y no haya
                finalizado el trámite respectivo.
              </p>
              <p>
                <b>(m) </b>Informar a solicitud del Titular sobre el uso dado a
                sus datos.
              </p>
              <p>
                <b>(n) </b>Informar a la autoridad de protección de datos cuando
                se presenten violaciones a los códigos de seguridad y existan
                riesgos en la administración de la información de los Titulares.
              </p>
              <p>
                <b>(o) </b>Cumplir las instrucciones y requerimientos que
                imparta la Superintendencia de Industria y Comercio.
              </p>
            </div>
            <div className="tituloDocumento" id="deberesEncargado">
              <h3>
                <b>
                  VIII. DEBERES DEL ENCARGADO DEL TRATAMIENTO DE DATOS
                  PERSONALES
                </b>
              </h3>
              <p>
                El Encargado del Tratamiento deberá cumplir los siguientes
                deberes, sin perjuicio de las demás disposiciones previstas en
                la ley:
              </p>
              <p>
                <b>a) </b>Garantizar al Titular, en todo tiempo, el pleno y
                efectivo ejercicio del derecho de hábeas data.
              </p>
              <p>
                <b>b) </b>Conservar la información bajo las condiciones de
                seguridad necesarias para impedir su adulteración, pérdida,
                consulta, uso o acceso no autorizado o fraudulento.
              </p>
              <p>
                <b>c) </b>Realizar oportunamente la actualización, rectificación
                o supresión de los datos en los términos de la ley 1581 de 2012.
              </p>
              <p>
                <b>d) </b>Actualizar la información reportada por los
                Responsables del Tratamiento dentro de los cinco (5) días
                hábiles contados a partir de su recibo.
              </p>
              <p>
                <b>e) </b>Tramitar las consultas y los reclamos formulados por
                los Titulares en los términos señalados en la presente política.
              </p>
              <p>
                <b>f) </b>Adoptar un manual interno de políticas y
                procedimientos para garantizar el adecuado cumplimiento de la
                ley 1581 de 2012 y, en especial, para la atención de consultas y
                reclamos por parte de los titulares.
              </p>
              <p>
                <b>g) </b>Registrar en la base de datos la leyenda “reclamo en
                trámite” en la forma en que se regula en la presente política.
              </p>
              <p>
                <b>h) </b>Insertar en la base de datos la leyenda “información
                en discusión judicial” una vez notificado por parte de la
                autoridad competente sobre procesos judiciales relacionados con
                la calidad del dato personal.
              </p>
              <p>
                <b>i) </b>Abstenerse de circular información que este siendo
                controvertida por el Titular y cuyo bloqueo haya sido ordenado
                por la Superintendencia de Industria y Comercio.
              </p>
              <p>
                <b>j) </b>Permitir el acceso a la información únicamente a las
                personas que pueden tener acceso a ella.
              </p>
              <p>
                <b>k) </b>Informar a la Superintendencia de Industria y Comercio
                cuando se presenten violaciones a los códigos de seguridad y
                existan riesgos en la administración de la información de los
                Titulares.
              </p>
              <p>
                <b>l) </b>Cumplir las instrucciones y requerimientos que imparta
                la Superintendencia de Industria y Comercio. En el evento en que
                concurran las calidades de Responsable del Tratamiento y
                Encargado del Tratamiento en la misma persona le será exigible
                el cumplimiento de los deberes previstos para cada uno.
              </p>
            </div>
            <div className="tituloDocumento" id="dataBases">
              <h3>
                <b>
                  IX. CLASES DE BASES DE DATOS Y FINALIDADES DEL TRATAMIENTO DE
                  DATOS PERSONALES
                </b>
              </h3>
              <p>
                Los Datos Personales que son incluidos en las Bases de Datos de
                HWI provienen de la información recopilada en ejercicio de las
                actividades desarrolladas en razón de los vínculos comerciales,
                contractuales, laborales o de cualquier otra índole con sus
                usuarios, proveedores, contratistas, empleados, clientes,
                accionistas y/o público en general, quiénes a través de los
                documentos que soporten la relación entre éstos y la Compañía
                autorizarán expresamente a ésta última para llevar a cabo el
                Tratamiento de Datos Personales en virtud de la Ley y la
                presente Política, la cual se encuentra disponible para ser
                solicitada para su consulta a través del correo electrónico
                datospersonales@hacebwhirlpool.com. De esta manera, HWI tiene
                diversas Bases de Datos las cuales se clasifican en cinco (5)
                grupos principales dependiendo de su naturaleza, composición y
                finalidades para el Tratamiento.
              </p>
              <p>
                La información suministrada por el Titular, será utilizada para
                los propósitos aquí señalados y en los casos en los que según la
                Ley no sea necesaria la autorización del Titular, y una vez cese
                la necesidad del Tratamiento de los Datos Personales, los mismos
                podrán ser eliminados de las Bases de Datos de HWI o archivados
                en términos seguros a efectos de solo ser divulgados cuando la
                ley así lo exija.
              </p>
              <table className="centered tablaPD">
                <thead>
                  <tr>
                    <th colSpan="3">1. Empleados</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3">
                      Bases de Datos que contienen información personal de los
                      siguientes grupos de interés: empleados actuales o
                      retirados, y candidatos a procesos de selección, entre
                      otros.
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1" style={{ width: "25%" }}>
                      <b> Función Principal</b>
                    </th>
                    <th colSpan="2">
                      <b> Explicación </b>
                    </th>
                  </tr>
                  <tr>
                    <th rowSpan="5" style={{ textAlign: "left" }}>
                      Gestión contable, fiscal y administrativa - Gestión
                      administrativa
                    </th>
                    <td>
                      Administración de la relación contractual, legal o
                      comercial que existe entre HWI y el Titular.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Conocimiento de los terceros con quienes la Compañía tiene
                      relación y realiza transacciones económicas con el fin de
                      dar cumplimiento a la reglamentación sobre prevención del
                      lavado de activos y financiación del terrorismo.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dar cumplimiento a las obligaciones legales de información
                      a los entes administrativos, así como a las autoridades
                      competentes que así lo requieran.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Compartir con terceros que colaboran con la Compañía y que
                      para el cumplimiento de sus funciones, deban acceder en
                      alguna medida a la información, tales como proveedores de
                      servicios de mensajería, empresas de seguridad y
                      vigilancia, proveedores de servicios de software, entre
                      otros. Se precisa en este punto que estos terceros están
                      obligados a mantener la confidencialidad de la información
                      a la que tienen acceso, todo lo cual se encuentra regulado
                      mediante acuerdos de confidencialidad y manejo de Bases de
                      Datos suscritos con los mismos.
                    </td>
                  </tr>
                  <tr>
                    <td>Soportar los procesos de auditoría de la Compañía.</td>
                  </tr>
                  <tr>
                    <th rowSpan="4">Recursos humanos - Gestión de personal</th>
                    <td>Administración de la relación laboral existente.</td>
                  </tr>
                  <tr>
                    <td>Envío de comprobantes, reportes y certificaciones</td>
                  </tr>
                  <tr>
                    <td>
                      Envío de información sobre beneficios derivados de la
                      relación y otorgados por HWI o por terceros con los que
                      éste tenga convenio.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Consulta en bases de datos públicas para verificación de
                      antecedentes.
                    </td>
                  </tr>
                  <tr>
                    <th>Recursos humanos - Gestión de nómina</th>
                    <td>
                      Pago de salario, prestaciones sociales, beneficios
                      extralegales y demás conceptos económicos asociados a la
                      relación laboral.
                    </td>
                  </tr>
                  <tr>
                    <th>Recursos humanos - Control de horario</th>
                    <td>
                      Seguimiento a horarios de ingreso y salida de empleados.
                    </td>
                  </tr>
                  <tr>
                    <th>Recursos humanos - Prestaciones sociales</th>
                    <td>
                      Realización de pagos, aportes y reportes a las entidades
                      de seguridad social, fondos de pensiones y cesantías,
                      compañías aseguradoras y cajas de compensación familiar.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Finalidades varias - Gestión de sanciones, amonestaciones,
                      llamados de atención, exclusiones
                    </th>
                    <td>
                      Realización de procesos disciplinarios, llamados de
                      atención y terminación de relación laboral.
                    </td>
                  </tr>
                  <tr>
                    <th>Recursos humanos - Formación de personal</th>
                    <td>
                      Seguimiento de asistencia a eventos, reuniones y
                      capacitaciones.
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan="2">
                      Recursos humanos - Promoción y selección de personal
                    </th>
                    <td>Envío de información sobre convocatorias laborales</td>
                  </tr>
                  <tr>
                    <td>
                      Realización de entrevistas, encuestas, pruebas
                      psicotécnicas, evaluaciones médicas y otro tipo de pruebas
                      y exámenes orientados a validaciones requeridas para la
                      relación laboral.
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan="2">
                      Publicidad y prospección comercial - Publicidad propia
                    </th>
                    <td>
                      Envío de información a través de diferentes medios, sobre
                      eventos, actividades, productos, servicios y en general,
                      información asociada a la actividad de la Compañía.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Adelantar convenios comerciales, eventos o programas
                      institucionales directamente o en asocio con terceros.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Publicidad y prospección comercial - Encuestas de opinión
                    </th>
                    <td>
                      Realizar encuestas de satisfacción y sobre asuntos
                      asociados con la relación laboral.
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="centered tablaPD">
                <thead>
                  <tr>
                    <th colSpan="3">
                      <b>2. Proveedores</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3">
                      Bases de Datos que contienen información de proveedores de
                      cualquier tipo de bienes y servicios para HWI
                    </td>
                  </tr>

                  <tr>
                    <th style={{ width: "25%" }}>Función Principal</th>
                    <th>Explicación</th>
                  </tr>

                  <tr>
                    <th rowSpan="5">
                      Gestión contable, fiscal y administrativa - Gestión
                      administrativa
                    </th>
                    <td>
                      Administración de la relación contractual, legal o
                      comercial que existe entre HWI y el Titular.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Conocimiento de los terceros con quienes la Compañía tiene
                      relación y realiza transacciones económicas con el fin de
                      dar cumplimiento a la reglamentación sobre prevención del
                      lavado de activos y financiación del terrorismo.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dar cumplimiento a las obligaciones legales de información
                      a los entes administrativos, así como a las autoridades
                      competentes que así lo requieran.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Compartir con terceros que colaboran con la Compañía y que
                      para el cumplimiento de sus funciones, deban acceder en
                      alguna medida a la información, tales como proveedores de
                      servicios de mensajería, empresas de seguridad y
                      vigilancia, proveedores de servicios de software, entre
                      otros. Se precisa en este punto que estos terceros están
                      obligados a mantener la confidencialidad de la información
                      a la que tienen acceso, todo lo cual se encuentra regulado
                      mediante acuerdos de confidencialidad y manejo de Bases de
                      Datos suscritos con los mismos.
                    </td>
                  </tr>
                  <tr>
                    <td>Soportar los procesos de auditoría de la Compañía.</td>
                  </tr>

                  <tr>
                    <th rowSpan="2">
                      Gestión contable, fiscal y administrativa - Gestión de
                      proveedores
                    </th>
                    <td>
                      Elaboración de contratos, órdenes de compra o servicios,
                      informes y reportes relacionados con la relación comercial
                      sostenida entre las partes.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Verificación de datos a través de consulta a bases de
                      datos públicas o centrales de riesgos.
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Gestión contable, fiscal y administrativa - Gestión de
                      facturación
                    </th>
                    <td>Gestión de anticipos, facturas y cuentas de cobro.</td>
                  </tr>

                  <tr>
                    <th>
                      Finalidades varias - Prestación de servicios de
                      certificación
                    </th>
                    <td>Emisión de certificados y comprobantes.</td>
                  </tr>

                  <tr>
                    <th rowSpan="3">
                      Publicidad y prospección comercial - Publicidad propia
                    </th>
                    <td>
                      Envío de información a través de diferentes medios, sobre
                      eventos, actividades, productos, servicios y en general,
                      información asociada a la actividad de la Compañía.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Adelantar convenios comerciales, eventos o programas
                      institucionales directamente o en asocio con terceros.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Envío de información sobre beneficios, eventos, nuevos
                      productos y servicios, trámites, convocatorias,
                      invitaciones a cotizar y en general información asociada a
                      la actividad de la Compañía.
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan="2">
                      Publicidad y prospección comercial - Encuestas de opinión
                    </th>
                    <td>Evaluar la calidad de la relación contractual.</td>
                  </tr>
                  <tr>
                    <td>
                      Realizar encuestas de satisfacción y sobre asuntos
                      asociados con la relación laboral.
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="centered tablaPD">
                <thead>
                  <tr>
                    <th colSpan="3">
                      <b>3. Relaciones corporativas</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3">
                      Bases de Datos que contienen información de grupos de
                      interés tales como: familiares de empleados, visitantes,
                      entre otros.
                    </td>
                  </tr>

                  <tr>
                    <th style={{ width: "25%" }}>Función Principal</th>
                    <th>Explicación</th>
                  </tr>

                  <tr>
                    <th rowSpan="5">
                      Gestión contable, fiscal y administrativa - Gestión
                      administrativa
                    </th>
                    <td>
                      Administración de la relación contractual, legal o
                      comercial que existe entre HWI y el Titular.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Conocimiento de los terceros con quienes la Compañía tiene
                      relación y realiza transacciones económicas con el fin de
                      dar cumplimiento a la reglamentación sobre prevención del
                      lavado de activos y financiación del terrorismo.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dar cumplimiento a las obligaciones legales de información
                      a los entes administrativos, así como a las autoridades
                      competentes que así lo requieran.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Compartir con terceros que colaboran con la Compañía y que
                      para el cumplimiento de sus funciones, deban acceder en
                      alguna medida a la información, tales como proveedores de
                      servicios de mensajería, empresas de seguridad y
                      vigilancia, proveedores de servicios de software, entre
                      otros. Se precisa en este punto que estos terceros están
                      obligados a mantener la confidencialidad de la información
                      a la que tienen acceso, todo lo cual se encuentra regulado
                      mediante acuerdos de confidencialidad y manejo de Bases de
                      Datos suscritos con los mismos.
                    </td>
                  </tr>
                  <tr>
                    <td>Soportar los procesos de auditoría de la Compañía.</td>
                  </tr>

                  <tr>
                    <th rowSpan="2">
                      Publicidad y prospección comercial - Publicidad propia
                    </th>
                    <td>
                      Envío de información a través de diferentes medios, sobre
                      eventos, actividades, productos, servicios y en general,
                      información asociada a la actividad de la Compañía.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Adelantar convenios comerciales, eventos o programas
                      institucionales directamente o en asocio con terceros.
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="centered tablaPD">
                <thead>
                  <tr>
                    <th colSpan="3">
                      <b>4. Clientes</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3">
                      Bases de Datos que contienen información de clientes de la
                      Compañía.
                    </td>
                  </tr>

                  <tr>
                    <th style={{ width: "25%" }}>Función Principal</th>
                    <th>Explicación</th>
                  </tr>

                  <tr>
                    <th rowSpan="5">
                      Gestión contable, fiscal y administrativa - Gestión
                      administrativa
                    </th>
                    <td>
                      Administración de la relación contractual, legal o
                      comercial que existe entre HWI y el Titular.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Conocimiento de los terceros con quienes la Compañía tiene
                      relación y realiza transacciones económicas con el fin de
                      dar cumplimiento a la reglamentación sobre prevención del
                      lavado de activos y financiación del terrorismo.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dar cumplimiento a las obligaciones legales de información
                      a los entes administrativos, así como a las autoridades
                      competentes que así lo requieran.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Compartir con terceros que colaboran con la Compañía y que
                      para el cumplimiento de sus funciones, deban acceder en
                      alguna medida a la información, tales como proveedores de
                      servicios de mensajería, empresas de seguridad y
                      vigilancia, proveedores de servicios de software, entre
                      otros. Se precisa en este punto que estos terceros están
                      obligados a mantener la confidencialidad de la información
                      a la que tienen acceso, todo lo cual se encuentra regulado
                      mediante acuerdos de confidencialidad y manejo de Bases de
                      Datos suscritos con los mismos.
                    </td>
                  </tr>
                  <tr>
                    <td>Soportar los procesos de auditoría de la Compañía.</td>
                  </tr>

                  <tr>
                    <th rowSpan="2">
                      Publicidad y prospección comercial - Publicidad propia
                    </th>
                    <td>
                      Envío de información a través de diferentes medios, sobre
                      eventos, actividades, productos, servicios y en general,
                      información asociada a la actividad de la Compañía.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Adelantar convenios comerciales, eventos o programas
                      institucionales directamente o en asocio con terceros.
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="centered tablaPD">
                <thead>
                  <tr>
                    <th colSpan="3">
                      <b>5. Accionistas</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3">
                      Bases de Datos que contienen información de los
                      accionistas de la Compañía.
                    </td>
                  </tr>

                  <tr>
                    <th style={{ width: "25%" }}>Función Principal</th>
                    <th>Explicación</th>
                  </tr>

                  <tr>
                    <th rowSpan="5">
                      Gestión contable, fiscal y administrativa - Gestión
                      administrativa
                    </th>
                    <td>
                      Administración de la relación contractual, legal o
                      comercial que existe entre HWI y el Titular.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Conocimiento de los terceros con quienes la Compañía tiene
                      relación y realiza transacciones económicas con el fin de
                      dar cumplimiento a la reglamentación sobre prevención del
                      lavado de activos y financiación del terrorismo.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dar cumplimiento a las obligaciones legales de información
                      a los entes administrativos, así como a las autoridades
                      competentes que así lo requieran.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Compartir con terceros que colaboran con la Compañía y que
                      para el cumplimiento de sus funciones, deban acceder en
                      alguna medida a la información, tales como proveedores de
                      servicios de mensajería, empresas de seguridad y
                      vigilancia, proveedores de servicios de software, entre
                      otros. Se precisa en este punto que estos terceros están
                      obligados a mantener la confidencialidad de la información
                      a la que tienen acceso, todo lo cual se encuentra regulado
                      mediante acuerdos de confidencialidad y manejo de Bases de
                      Datos suscritos con los mismos.
                    </td>
                  </tr>
                  <tr>
                    <td>Soportar los procesos de auditoría de la Compañía.</td>
                  </tr>

                  <tr>
                    <th rowSpan="2">
                      Convocatorias, publicidad y prospección comercial -
                      Publicidad propia
                    </th>
                    <td>
                      Envío de información a través de diferentes medios, sobre
                      reuniones, eventos, actividades, productos, servicios y en
                      general, información asociada a la actividad de la
                      Compañía.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Adelantar convenios comerciales, eventos o programas
                      institucionales directamente o en asocio con terceros.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="tituloDocumento" id="procedimientos">
              <h3>
                <b>X. PROCEDIMIENTOS PARA EL TRATAMIENTO DE DATOS PERSONALES</b>
              </h3>

              <p>
                En HWI se ha designado un oficial de Habeas Data, quien es el
                encargado de liderar al interior de la Compañía toda la gestión
                asociada a las Bases de Datos. Nuestro Oficial de Habeas Data ha
                diseñado un Programa Integral de Gestión de Bases de Datos
                consistente en lo siguiente:
              </p>
              <p>
                <b>• </b>Conformación de un comité compuesto por miembros
                permanentes, el cual se encarga de diseñar, implementar, hacer
                cumplir y realizar seguimiento al Programa Integral de Gestión
                de Bases de Datos.
              </p>
              <p>
                <b>• </b>Inventario definido sobre las Bases de Datos existentes
                en la Compañía.
              </p>
              <p>
                <b>• </b>A cada una de las Bases de Datos identificadas se ha
                designado un Líder interno, quien es el encargado de controlar
                la Base de Datos a su cargo.
              </p>
              <p>
                <b>• </b>Se han implementado medidas de seguridad aplicables de
                manera general y específica a las bases de Datos.
              </p>
              <p>
                <b>• </b>Se ha trazado un plan de acción y mejora continua con
                el fin de asegurar cada una de las Bases de Datos y garantizar
                el adecuado manejo y Tratamiento de las mismas.
              </p>
              <p>
                <b>• </b>Para el Tratamiento de Datos Sensibles, HWI informará a
                los Titulares de la información: (i) que por tratarse de este
                tipo de datos, no está obligado a autorizar su Tratamiento e
                (ii) informará cuales Datos son Sensibles y la finalidad del
                Tratamiento.
              </p>
              <p>
                <b>• </b>Para efectos del Tratamiento de Datos Personales de
                niños, niñas y adolescentes, la Compañía responderá y respetará
                el interés superior de estos y además, asegurará el respeto de
                sus derechos fundamentales.
              </p>
            </div>
            <div className="tituloDocumento" id="modificaciones">
              <h3>
                <b>XI. MODIFICACIONES A LA POLÍTICA</b>
              </h3>
              <p>
                La Compañía se reserva el derecho de modificar la Política en
                cualquier momento. Toda modificación se comunicará de forma
                oportuna a los Titulares de los datos a través de los medios
                habituales de contacto y/o manteniéndose disponible para su
                consulta a través del correo electrónico
                <b>datospersonales@hacebwhirlpool.com</b> con quince (15) días
                hábiles de antelación a su entrada en vigencia.
              </p>
              <p>
                En caso de no estar de acuerdo por razones válidas y que se
                constituyan en una justa causa con las nuevas políticas de
                manejo de los datos personales, los Titulares de la información
                o sus representantes podrán solicitar a la Compañía el retiro de
                su información a través de los medios indicados previamente en
                esta Política. Sin embargo, no se podrá solicitar el retiro de
                dicha información mientras se mantenga un vínculo de cualquier
                orden con la entidad u obedezca a una obligación de carácter
                legal.
              </p>
            </div>
            <div className="tituloDocumento" id="internacional">
              <h3>
                <b>
                  XII. UTILIZACIÓN Y TRANSFERENCIA INTERNACIONAL DE DATOS
                  PERSONALES E INFORMACIÓN PERSONAL POR PARTE DE LA COMPAÑÍA
                </b>
              </h3>
              <p>
                La Compañía, atendiendo a la naturaleza de las relaciones
                permanentes u ocasionales que cualquier persona Titular de datos
                personales pueda tener para con la misma, podrá realizar la
                transferencia y transmisión, incluso internacional, de la
                totalidad de los datos personales, siempre y cuando se cumplan
                los requerimientos legales aplicables. En consecuencia, los
                Titulares con la aceptación de la presente Política, autorizan
                expresamente para transferir y transmitir, incluso a nivel
                internacional, los datos personales. Los datos serán
                transferidos, para todas las relaciones que puedan establecerse
                con la Compañía.
              </p>
              <p>
                Para la transferencia internacional de datos personales de los
                Titulares, la Compañía tomará las medidas necesarias para que
                los terceros conozcan y se comprometan a observar la presente
                Política, bajo el entendido que la información personal que
                reciban, únicamente podrá ser utilizada para asuntos
                directamente relacionados con la Compañía y solamente mientras
                estos duren y no podrá ser usada o destinada para propósito o
                fin diferente. Para la transferencia internacional de datos
                personales se observará lo previsto en el artículo 26 de la Ley
                1581 de 2012.
              </p>
              <p>
                La Compañía, también podrá intercambiar información personal con
                autoridades gubernamentales o públicas de otro tipo (incluidas,
                entre otras autoridades judiciales o administrativas,
                autoridades fiscales y organismos de investigación penal, civil,
                administrativa, disciplinaria y fiscal), y terceros
                participantes en procedimientos legales civiles y sus
                contadores, auditores, abogados y otros asesores y
                representantes, porque es necesario o apropiado: (a) para
                cumplir con las leyes vigentes, incluidas las leyes distintas a
                las de su país de residencia; (b) para cumplir con procesos
                jurídicos; (c) para responder las solicitudes de las autoridades
                públicas y del gobierno, y para responder las solicitudes de las
                autoridades públicas y del gobierno distintas a las de su país
                de residencia; (d) para hacer cumplir nuestros términos y
                condiciones; (e) para proteger nuestras operaciones; (f) para
                proteger nuestros derechos, privacidad, seguridad o propiedad,
                los suyos o los de terceros; y (g) obtener las indemnizaciones
                aplicables o limitar los daños y perjuicios que nos puedan
                afectar.
              </p>
            </div>
            <div className="tituloDocumento" id="mecanismos">
              <h3>
                <b>
                  XIII. MECANISMOS DISPUESTOS POR HWI COMO RESPONSABLE DEL
                  TRATAMIENTO DE DATOS PERSONALES
                </b>
              </h3>
              <p>
                En aras de proteger y mantener la confidencialidad de los Datos
                Personales de los Titulares, la Compañía determina que el
                procedimiento para conocer, actualizar, rectificar, suprimir
                información o revocar la autorización para el Tratamiento de
                Datos Personales, implica el deber del Titular de contactarse
                con HWI a través de los medios dispuestos para ello, a saber:
                (i) Realizando la solicitud telefónicamente a través de la línea
                de atención dispuesta para ello; (ii) Remitiendo solicitud por
                escrito escaneada al correo electrónico dispuesto por la
                Compañía, la cual deberá ser acompañada de una copia del
                documento de identidad del Titular o (iii) Remitiendo solicitud
                por escrito al domicilio social de la Compañía, la cual deberá
                ser acompañada de una copia del documento de identidad del
                Titular.
              </p>
              <p>
                Los Titulares de los Datos Personales, podrán en todo momento,
                solicitar a HWI la supresión de sus datos y/o revocar la
                autorización, mediante la presentación de un reclamo de acuerdo
                con lo establecido en el artículo 15 de la Ley 1581 de 2012.
              </p>
              <p>
                Resulta indispensable advertir que la solicitud de supresión de
                la información y la revocatoria de la autorización, no procederá
                cuando Titular tenga un deber legal o contractual con HWI.
              </p>
              <br />
              <table
                className="centered tablaPD"
                style={{ width: "60%", margin: "auto" }}
              >
                <thead>
                  <tr>
                    <th>CANAL</th>
                    <th>EMPLEADOS, PROVEEDORES Y RELACIONES CORPORATIVAS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Correo Electrónico</th>
                    <td>datospersonales@hacebwhirlpool.com</td>
                  </tr>
                  <tr>
                    <th>Teléfono</th>
                    <td>(057) 5408470 ext. 4107</td>
                  </tr>
                  <tr>
                    <th>Punto de Atención</th>
                    <td>Carrera 48 calle 59-21. Oficina Talento Humano</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tituloDocumento" id="vigencia">
              <h3>
                <b>XIV. LEY, JURISDICCIÓN Y VIGENCIA DE LA POLÍTICA</b>
              </h3>
              <p>
                <b>• </b>Constitución Política de 1991, artículo 15
              </p>
              <p>
                <b>• </b>Ley 1266 de 2008
              </p>
              <p>
                <b>• </b>Ley 1581 de 2012
              </p>
              <p>
                <b>• </b>Decreto reglamentario 1727 de 2009
              </p>
              <p>
                <b>• </b>Decreto reglamentario 2952 de 2010
              </p>
              <p>
                <b>• </b>Decreto 1377 de 2013
              </p>
              <p>
                <b>• </b>Decreto 886 de 2014
              </p>
              <br />
              <p>
                La presente Política de Tratamiento de Datos Personales de HWI,
                rige a partir del 01 de marzo de 2017
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.firebase.auth, profile: state.firebase.profile };
};

export default connect(mapStateToProps)(politicaDatos);
