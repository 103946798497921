import React, { Component } from "react";
import "./certificaciones.css";
import Modal from "react-awesome-modal";
import iso1 from "../../images/ISO-9001-2015.JPG";
import iso2 from "../../images/ISO-14001-2015.JPG";
import iso3 from "../../images/ISO-18001-2007.JPG";
import oea from "../../images/OEA.jpg";

class certificaciones extends Component {
  state = {
    visible: false,
    srcImage: ""
  };

  handleOpenModal(e) {
    const src = e.target.src;
    this.setState({
      visible: true,
      srcImage: src
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  render() {
    return (
      <>
        <div className="todoCertificados" id="certificaciones">
          <h1>Certificación en Procesos</h1>
          <section className="sectionCertificados">
            <div className="gridCertificados">
              <div className="cajaCertificado">
                <img
                  src={iso1}
                  alt="ISO 9001"
                  onClick={this.handleOpenModal.bind(this)}
                />
              </div>
              <div className="cajaCertificado">
                <img
                  src={iso2}
                  alt="ISO 14001"
                  onClick={this.handleOpenModal.bind(this)}
                />
              </div>
              <div className="cajaCertificado">
                <img
                  src={iso3}
                  alt="ISO 18001"
                  onClick={this.handleOpenModal.bind(this)}
                />
              </div>
              <div className="cajaCertificado vacio"></div>
              <div className="cajaCertificado">
                <img
                  src={oea}
                  alt="ISO 18001"
                  onClick={this.handleOpenModal.bind(this)}
                />
              </div>
              <div className="cajaCertificado vacio"></div>
            </div>
            <Modal
              visible={this.state.visible}
              width="0"
              height="90%"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div className="contenidoModal">
                <img
                  className="imageModal"
                  src={this.state.srcImage}
                  alt="Certificado"
                />
              </div>
            </Modal>
          </section>
        </div>
      </>
    );
  }
}

export default certificaciones;
