import React, { Component } from "react";
import { connect } from "react-redux";
import { LogIn } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";
import "./login.css";
import image1 from "../../images/login/1.jpg"
import image2 from "../../images/login/2.jpg"
import image3 from "../../images/login/3.jpg"
import image4 from "../../images/login/4.jpg"
import image5 from "../../images/login/5.jpg"
import image6 from "../../images/login/6.jpg"
import image7 from "../../images/login/7.jpg"
import image8 from "../../images/login/8.jpg"
import image9 from "../../images/login/9.jpg"
import image10 from "../../images/login/10.jpg"
import image11 from "../../images/login/11.jpg"
import image12 from "../../images/login/12.jpg"

class IniciarSesion extends Component {
  state = {
    correo: "",
    contraseña: ""
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.LogIn(this.state);
    // console.log(this.state);
    // console.log(this.props);
  };
  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to="/" />;

    return (
      <div className="body-login">
        <div className="row row-form" style={{ width: "100vw", margin: "0" }}>
          <div className="col s12 m12 l8" style={{ padding: "0" }}>
            <div className="grid-imagenes">
            <div className="imagen" style={{backgroundImage: "url(" + image1 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image2 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image3 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image4 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image5 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image6 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image7 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image8 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image9 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image10 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image11 + ")"}}></div>
            <div className="imagen" style={{backgroundImage: "url(" + image12 + ")"}}></div>
            </div>
          </div>
          <div className="col s12 m12 l4" style={{ padding: "0", maxHeight:"calc(100vh)", backgroundColor: "#131419"}}>
            <div className="formulario-login">
              <h2 className="titulo-login" style={{ letterSpacing: "10px" }}>
                Iniciar sesión
              </h2>
              <form onSubmit={this.handleSubmit}>
                <div className="input">
                  <div className="inputbox">
                    <label htmlFor="correo">Correo electrónico</label>
                    <input
                      type="email"
                      name="correo"
                      id="correo"
                      placeholder="correo@direccion.com"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="inputbox">
                    <label htmlFor="contraseña">Contraseña</label>
                    <input
                      type="password"
                      name="contraseña"
                      id="contraseña"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="inputbox">
                    <button>Ingresar</button>
                    <div className="red-text center">
                      {authError ? <p>{authError}</p> : null}
                    </div>
                  </div>
                </div>
              </form>

              {/* <p className="olvidada">
                ¿Olvidaste la contraseña? <a href="">Click aquí</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  // console.log(state);
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    LogIn: (creds) => dispatch(LogIn(creds))
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(IniciarSesion);
