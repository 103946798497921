import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
// import ListaDocumento from "../documentos/listaDocumento";
// import ListaAuditoria from "../documentos/listaAuditoria";
import TarjetasDocumentos from "../documentos/tarjetasDocumentos";
import "./proveedorCalidad.css";

class ProveedorPlaneacion extends Component {

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  }
  render() {
    const { usuario, auth, documentos } = this.props;
    if (!auth.uid) return <Redirect to="/iniciarSesion" />;

    if (usuario && usuario.area === "calidad" && documentos) {
      return (
        <>
            <div className="contenedor-pagina">
              <div
                className="row center"
                style={{ minHeight: "500px", padding: "0 0 0 0" }}
              >
                <h3 className="tituloCalidad">Tabla por # de piezas</h3>
                <div className="banner-tabla">
                  <div className="contenedor-tabla">
                    <table className="tabla-calidad">
                      <thead>
                        <tr>
                          <th>PartNumber</th>
                          <th>Nombre de la pieza</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Alvin</td>
                          <td>Eclair</td>
                        </tr>
                        <tr>
                          <td>Alan</td>
                          <td>Jellybean</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="container">
                <hr
                  style={{
                    width: "80%",
                    margin: "6em auto",
                    border: "solid 2px teal"
                  }}
                />
                <div className="row" style={{ minHeight: "500px" }}>
                  <div className="col s12 m6 l6">
                    <iframe
                      title="dataStudio"
                      width="100%"
                      height="315"
                      src={usuario.dataStudio}
                      frameBorder="none"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                      style={{ marginTop: "50px" }}
                    />
                  </div>
                  <div className="col s12 m6 l6 center">
                    <h3>
                      <span style={{ color: "teal", fontSize: "25px" }}>
                        Estado de PPAPs
                      </span>
                      <br /> {usuario.nombre} - HWI
                    </h3>
                    <div className="left-align" style={{ marginLeft: "15%" }}>
                      Dentro de este indicador puedes encontrar:
                      <ul>
                        <li>Niveles de servicio</li>
                        <li>Cumplimiento de entregas</li>
                        <li>Entregas pendientes</li>
                        <li>Niveles de satisfacción</li>
                      </ul>
                    </div>
                    <blockquote className="left-align linea-izquierda">
                      Recuerda que para ingresar al indicador debes acceder con
                      la cuenta de correo electrónico vinculada a la base de
                      datos de HWI.
                    </blockquote>
                  </div>
                </div>
                <hr
                  style={{
                    width: "80%",
                    margin: "6em auto",
                    border: "solid 2px teal"
                  }}
                />
                {/*<h5 className="black-text center">Auditorías GSQA: </h5>
             <div className="row">
              <ListaAuditoria documentos={documentos} id={auth.uid} />
            </div>
            <hr
              style={{
                width: "80%",
                margin: "6em auto",
                border: "solid 2px teal"
              }}
            />
            <h3 className="black-text center">Documentos de interés</h3>
            <div className="row valign-wrapper" style={{ minHeight: "300px" }}>
              <ListaDocumento
                documentos={documentos}
                area={usuario.area}
                id={auth.uid}
              />
            </div> */}
              </div>
              <TarjetasDocumentos
                documentos={documentos}
                area={usuario.area}
                id={auth.uid}
              />
            </div>
        </>
      );
    } else {
      return <div>No se ha podido cargar</div>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const usuarios = state.firestore.data.usuarios;
  const documentos = state.firestore.ordered.documentos;
  const usuario = usuarios ? usuarios[id] : null;

  return {
    usuario: usuario,
    auth: state.firebase.auth,
    documentos: documentos
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "usuarios" }, { collection: "documentos" }])
)(ProveedorPlaneacion);
