import React from "react";
import Resumen from "./resumenDocumento";
// import { Link } from "react-router-dom";

const ListaDocumento = ({ documentos, area, id }) => {
  return (
    <>
      {documentos &&
        documentos.map(documento => {
          if (documento.area === area && documento.uid === id && documento.tipoDocumento === "documento") {
            return (
                <Resumen documento={documento} key={documento.id} />
            );
          }else{
            return null;
          }
        })}
    </>
  );
};

export default ListaDocumento;
