import React from "react";
import imagen from "../../images/backingMural.jpg";
import "./quienesSomos.css";
import Valores from "./valores";
import MisionVision from './misionVision'

const aboutUs = () => {
  return (
    <>
      <section style={{ paddingTop: "5em" }} id="quienesSomos">
        <div className="container">
          <h3 className="center">¿Quiénes Somos?</h3>
          <div className="row">
            <div
              className="col l6 m6 s12 valign-wrapper"
              style={{ Height: "500px" }}
            >
              <img
                src={imagen}
                alt=""
                style={{ margin: "auto", height: "300px", maxWidth: "100%" }}
              />
            </div>
            <div
              className="col l6 m6 s12 valign-wrapper"
              style={{ Height: "500px" }}
            >
              <p
                style={{
                  textAlign: "justify",
                  letterSpacing: "1.5px",
                  fontSize: "20px"
                }}
              >
                Somos el resultado de la inversión de dos grandes líderes del
                sector de electrodomésticos de Colombia y del mundo para la
                creación de la primera fábrica de lavadoras del país. <br /> Nos
                beneficiamos de la oportunidad de un mercado Colombiano
                potencial con una demanda estimada en 1 millón de unidades por
                año, más el potencial de industria del resto de la región
                Andina.
              </p>
            </div>
          </div>
        </div>
      </section>
      <MisionVision />
      <Valores />
    </>
  );
};

export default aboutUs;
