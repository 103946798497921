import React from "react";
import "./cumplimiento.css";

const cumplimiento = () => {
  return (
    <>
      <div className="todo">
        <section className="sectionCumplimiento" id="politicasCumplimiento">
          <div className="contenidoCumplimiento">
            <h1>Cumplimiento</h1>
            <p className="lead">
              Conjunto de medidas, políticas y procedimientos que HWI ha
              establecido para garantizar una conducta ética y respetuosa de la
              ley, tanto de sus empleados, como de todos sus grupos de interés.
            </p>
            <p>
              Aquí tienes acceso a los <b>manuales</b>, <b>políticas</b> y demás
              documentación que hacen parte de este proceso, además de poder
              acceder a una herramienta totalmente <b>anónima</b> para
              <b>denunciar</b> cualquier anomalía.
            </p>
            <div className="row">
              <div className="col s6 center">
                <ul className="dropdown">
                  <li>
                    Manuales/Documentos
                    <ul>
                      <li>
                        <a href="https://drive.google.com/open?id=1mCseznWEKaL6EHy14jw5PfviXgSwJb2G" rel="noopener noreferrer" target="_blank">
                          Manual SAGRLAFT (Español)
                        </a>
                      </li>
                      <li>
                        <a href="https://drive.google.com/open?id=19jDMR5vjFNjRz8Sjy9WBa8pQNrU6eRHr" rel="noopener noreferrer" target="_blank">
                          Programa Ética Empresarial
                        </a>
                      </li>
                      <li>
                        <a href="https://drive.google.com/open?id=1ZaP4ITldnavA6N4OHJLknp_PCkuNpLDa" rel="noopener noreferrer" target="_blank">
                          Código de Conducta Proveedores
                        </a>
                      </li>
                      <li>
                        <a href="https://drive.google.com/file/d/14qvLgcwqJGZe3wT3rmrgtLp8IfObGQSp/view?usp=sharing" rel="noopener noreferrer" target="_blank">
                          Política de Regalos
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="contenedorImages">
            <div className="cajaImage"></div>
            <div className="cajaImage"></div>
          </div>
        </section>
      </div>
    </>
  );
};

export default cumplimiento;
