const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      // console.log("No se pudo iniciar sesión");
      return {
        ...state,
        authError: "Inicio de sesión erróneo"
      };
    case "LOGIN_SUCCESS":
      // console.log("login completado");
      return {
        ...state,
        authError: null
      };
    case "SIGNOUT_SUCCESS":
      // console.log("Sesión cerrada");
      return state;
    case "REGISTRO_COMPLETO":
      // console.log("Registro exitoso"); 
      return { ...state, authError: null };
    case 'REGISTRO_ERROR':
      // console.log('El registro no tuvo éxito', action.err.message);
      return{
        ...state,
        authError: action.err.message
      }
    default:
      return state;
  }
};

export default authReducer;
