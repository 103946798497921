import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import slider2 from "../../images/slide2.jpg";
import slider1 from "../../images/slide1.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QuienesSomos from "../empresa/quienesSomos";
import { connect } from "react-redux";
import NavLateral from "../layout/navLateral";
import VideoCubo from "../empresa/videoCubo";
import Cumplimiento from "../empresa/cumplimiento";
import Certificados from "../empresa/certificaciones";
import "./home.css";

const imagenesBanner = [
  {
    nombre: "slider1",
    url: slider1
  },
  {
    nombre: "slider2",
    url: slider2
  }
];

class Home extends Component {

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  }

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/iniciarSesion" />;
    const settings = {
      dots: false,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 8000,
      arrows: false
    };
    return (
      <>
          <>
            <div className="carrusel-principal" style={{ height: "100vh" }}>
              <div
                className="oscurecido"
                style={{
                  position: "absolute",
                  top: "0",
                  height: "100vh",
                  maxHeight: "100vh",
                  width: "100%",
                  background: "rgba(0,0,0,0.5)",
                  zIndex: "1"
                }}
              ></div>
              <div
                className="titulo"
                style={{
                  // backgroundColor: "red",
                  width: "60%",
                  position: "absolute",
                  zIndex: "1",
                  left: "20%",
                  marginTop: "15%"
                }}
              >
                <h1
                  style={{
                    color: "white",
                    width: "100%",
                    fontSize: "4rem",
                    display: "inline-block",
                    zIndex: "1",
                    fontWeight: "600"
                    // background: "black"
                  }}
                  id="tituloPortal"
                >
                  Portal de proveedores <br /> Haceb Whirlpool Industrial S.A.S.
                </h1>
                <p
                  style={{
                    color: "white",
                    // width: "750px",
                    fontSize: "1.5rem",
                    display: "block",
                    zIndex: "1"
                    // backgroundColor: "green"
                  }}
                  className="hide-on-small-only"
                >
                  Plataforma de control e información de procesos mediante datos
                </p>
              </div>
              <Slider
                {...settings}
                style={{ maxHeight: "100vh", width: "100vw" }}
              >
                {imagenesBanner.map((foto) => {
                  return (
                    <div key={foto.nombre}>
                      <img
                        src={foto.url}
                        alt={foto.nombre}
                        style={{
                          minHeight: "100vh",
                          maxHeight: "100vh",
                          width: "100vw"
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <NavLateral />
            <QuienesSomos />
            <VideoCubo />
            <Cumplimiento />
            <Certificados />
          </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.firebase.auth, profile: state.firebase.profile };
};

export default connect(mapStateToProps)(Home);
