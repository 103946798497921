import React, { Component } from "react";
import "./misionVision.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import misionImage from "../../images/mision_vision.png";
import plantaImage from "../../images/planta.jpg";

class misionVision extends Component {
  render() {
    const settings1 = {
      dots: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 10000,
      arrows: false,
    };
    return (
      <>
        <div className="todoMision">
          <section className="sectionMisionVision">
            <Slider
              {...settings1}
              style={{ height: "80vh", width: "90vw", margin: " 0 auto" }}
            >
              <div className="mision">
                <img
                  src={plantaImage}
                  alt="Mision HWI"
                  style={{ maxHeight: "80vh", width: "90vw", maxWidth: "90vw" }}
                />
              </div>
              <div className="vision">
                <img
                  src={misionImage}
                  alt="Pilares HWI"
                  style={{ maxHeight: "80vh", width: "90vw", maxWidth: "90vw" }}
                />
              </div>
            </Slider>
          </section>
        </div>
      </>
    );
  }
}

export default misionVision;
