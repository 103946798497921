const initState = {};

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREAR_DOCUMENTO":
      console.log("Documento creado", action.documento);
      return state;
    case "CREAR_DOCUMENTO_ERROR":
      console.log("Error creando el documento", action.err);
      return state;
    default:
      return state;
  }
};

export default projectReducer;
