import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ListaDocumento from "../documentos/listaDocumento";
import "./tesoreria.css";

class ProveedorPlaneacion extends Component {
  state = {
    type: "",
    dateFinal: new Date(),
    dateInicial: new Date(),
    resultcons: [],
    loading: false
  };

  formatoFecha = (fecha) => {
    let dia = fecha.getDate().toString();

    let mes = (1 + fecha.getMonth()).toString();
    mes = mes.length > 1 ? mes : "0" + mes;

    let año = fecha.getFullYear();

    return dia + "/" + mes + "/" + año;
  };

  changeRadio = (e) => {
    this.setState({
      type: e
    });
  };

  obtenerFechas = () => {
    let fechaInicial = new Date(this.state.dateInicial);
    let fechaFinal = new Date(this.state.dateFinal);
    let diasDif = fechaFinal.getTime() - fechaInicial.getTime();
    let contDias = Math.round(diasDif / (1000 * 60 * 60 * 24));
    let fechas = [];
    for (
      ;
      fechas.length < contDias;
      fechaInicial.setDate(fechaInicial.getDate() + 1)
    ) {
      let mes = (1 + fechaInicial.getMonth()).toString();
      mes = mes.length > 1 ? mes : "0" + mes;
      if (fechaInicial.getDate() > 0) {
        fechas.push(
          fechaInicial.getDate() + "/" + mes + "/" + fechaInicial.getFullYear()
        );
      }
    }

    return fechas;
  };

  consultarDoc = (e) => {
    e.preventDefault();
    if (this.state.type === "") {
      return alert("Debes seleccionar un tipo de documento");
    }
    this.setState({
      loading: true
    });

    let apiResult,
      fechaInicial = new Date(this.state.dateInicial),
      fechaFinal = new Date(this.state.dateFinal),
      usuario = this.props.usuario,
      añoInicial = fechaInicial.getFullYear(),
      añoFinal = fechaFinal.getFullYear();
    let url =
      "https://script.google.com/a/macros/hacebwhirlpool.com/s/AKfycbw6ycpcjdc_Ix0Fu19AKwIvYCJNHs2JMtZqC9Mm-YB-Z4Se9HGM/exec?action=apiDatos&cuenta=" +
      usuario.cuenta +
      "&añoInicial=" +
      añoInicial +
      "&añoFinal=" +
      añoFinal;

    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let fechas = this.obtenerFechas();
          apiResult = result.filter((doc) => {
            if (this.state.type === "pagado") {
              return fechas.includes(doc.fechaCompensacion);
            } else if (this.state.type === "pendiente") {
              return doc.fechaCompensacion === "";
            } else return null;
          });
          apiResult.sort(function(a, b) {
            return (
              new Date(b.fechaCompensacion) - new Date(a.fechaCompensacion)
            );
          });
          this.setState({
            resultcons: apiResult,
            loading: false
          });
        },
        (error) => {
          alert("Hubo un error " + error);
        }
      );
  };

  render() {
    const { usuario, auth, documentos } = this.props;
    const { loading } = this.state;

    if (!auth.uid) return <Redirect to="/iniciarSesion" />;

    if (usuario && usuario.area === "financiera" && documentos) {
      let p = 0;
      return (
        <>
          <div className="contenedor-pagina">
            <div className="container" style={{ marginTop: "7em" }}>
              <div className="row center">
                <div
                  className="col s12 m4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90px"
                  }}
                >
                  <div className="contenedor-selector">
                    <div className="selector">
                      <input
                        type="radio"
                        name="type"
                        onChange={() => this.changeRadio("pagado")}
                        id="type1"
                      />
                      <label htmlFor="type1" className="labelType1">
                        <span>Cuentas Pagadas</span>
                      </label>
                      <input
                        type="radio"
                        name="type"
                        onChange={() => this.changeRadio("pendiente")}
                        id="type2"
                      />
                      <label htmlFor="type2" className="labelType2">
                        <span>Cuentas por Pagar</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className="col s12 m5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90px"
                  }}
                >
                  <div
                    className="center"
                    style={{
                      display: "inline-block",
                      margin: "0 10px 0 10px",
                      padding: "5px"
                    }}
                  >
                    <span className="tittle">Fecha inicial</span>
                    <DatePicker
                      className="center hoverable fecha"
                      selected={this.state.dateInicial}
                      onChange={(date) => {
                        this.setState({ dateInicial: date });
                      }}
                      maxDate={new Date()}
                      minDate={new Date("11/1/2014")}
                    />
                  </div>
                  <div
                    className="center"
                    style={{
                      display: "inline-block",
                      margin: "0 10px 0 10px",
                      padding: "5px"
                    }}
                  >
                    <span className="tittle">Fecha Límite</span>
                    <DatePicker
                      style={{ width: "12px" }}
                      className="center hoverable fecha"
                      selected={this.state.dateFinal}
                      onChange={(date) => {
                        this.setState({ dateFinal: date });
                      }}
                      maxDate={new Date()}
                      minDate={new Date("11/1/2014")}
                    />
                  </div>
                </div>
                <div
                  className="col s12 m3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90px"
                  }}
                >
                  <button
                    className="btn waves-effect waves-light"
                    style={{ fontSize: "12px", marginLeft: "20px" }}
                    onClick={this.consultarDoc}
                    disabled={loading}
                  >
                    {loading && <i className="fa fa-refresh fa-spin"></i>}
                    Consultar
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  {loading && (
                    <div className="progress">
                      <div className="indeterminate"></div>
                    </div>
                  )}
                  <table
                    className="striped centered highlight responsive-table"
                    style={{
                      border: "solid 2px black",
                      maxHeight: "400px",
                      overflowY: "auto"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>N° de Factura</th>
                        <th>Fecha de Pago</th>
                        <th>Fecha de Pago Documento</th>
                        <th>Valor de la Factura</th>
                        <th>Moneda del Documento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.resultcons.map((doc) => {
                        return (
                          <tr key={p++}>
                            <td>{doc.referencia}</td>
                            <td>{doc.fechaCompensacion}</td>
                            <td>{doc.fechaPago}</td>
                            <td>{doc.importeMonedaDoc}</td>
                            <td>{doc.monedaDoc}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <hr
                style={{
                  width: "80%",
                  margin: "6em auto",
                  border: "solid 2px teal"
                }}
              />
              <h3 className="black-text center">Documentos de interés</h3>
              <div
                className="row valign-wrapper"
                style={{ minHeight: "300px" }}
              >
                <ListaDocumento
                  documentos={documentos}
                  area={usuario.area}
                  id={auth.uid}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <div>No se ha podido cargar</div>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const usuarios = state.firestore.data.usuarios;
  const documentos = state.firestore.ordered.documentos;
  const usuario = usuarios ? usuarios[id] : null;

  return {
    usuario: usuario,
    auth: state.firebase.auth,
    documentos: documentos
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "usuarios" }, { collection: "documentos" }])
)(ProveedorPlaneacion);
