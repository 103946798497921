import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { crearDocumento } from "../../store/actions/documentoActions";
import { Redirect } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import "./nuevoDocumento.css";

class CrearDocumento extends Component {
  state = {
    nombre: "",
    mes: "",
    url: "",
    uid: "",
    nombreProveedor: "",
    correoProveedor: "",
    correoUsuario: "",
    tipoDocumento: "",
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    // console.log(this.props);
  };
  handleChangeSelect = (newValue, actionMeta) => {
    if (actionMeta.action !== "clear") {
      this.setState({
        uid: newValue.value,
        nombreProveedor: newValue.label,
      });
    } else {
      this.setState({
        uid: "",
        nombreProveedor: "",
      });
    }
  };
  handleChangeSelectTipo = (newValue, actionMeta) => {
    if (actionMeta.action !== "clear") {
      this.setState({
        [actionMeta.name]: newValue.value,
      });
    } else {
      this.setState({
        [actionMeta.name]: "",
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.nombreProveedor !== "") {
      const users = this.props.usuarios;
      const profile = this.props.profile;
      const mapeoProveedor =
        users &&
        profile &&
        users.map((usuario) => {
          if (
            usuario.nombre === this.state.nombreProveedor &&
            usuario.area === profile.area
          ) {
            return usuario;
          } else {
            return null;
          }
        });
      const filtroProveedor =
        mapeoProveedor &&
        mapeoProveedor.filter((e) => {
          return e !== null && e !== undefined;
        });

      this.props.crearDocumento(this.state);
      const templateId = "notificacionproveedor";
      this.enviarCorreo(templateId, {
        nombreEmpresa: "Portal HWI",
        nombreProveedor: this.state.nombreProveedor,
        nombreDocumento: this.state.nombre,
        correoPara: filtroProveedor[0].correo,
        copiaPara: profile.correo,
        linkDocumento: this.state.url,
      });
      this.props.history.push("/");
    } else {
      alert("debe seleccionar un proveedor");
    }
  };

  enviarCorreo(templateId, variables) {
    window.emailjs
      .send("gmail", templateId, variables)
      .then((res) => {
        alert("Se ha enviado el correo correctamente");
      })
      .catch((err) => {
        console.error("hubo un error: " + err);
        alert("hubo un error: " + err);
      });
  }

  render() {
    const { auth, usuarios, profile } = this.props;

    const opciones =
      usuarios &&
      profile &&
      usuarios.map((usuario) => {
        if (
          (usuario.rol === "proveedor" || usuario.rol === "proveedorCS") &&
          usuario.area === profile.area
        ) {
          return {
            value: usuario.id,
            label: usuario.nombre,
          };
        } else {
          console.log("NaN");
          return null;
        }
      });

    const options =
      opciones &&
      opciones.filter((e) => {
        return e !== null && e !== undefined;
      });

    const optionsTipo = [
      { value: "auditoria", label: "Auditoría" },
      { value: "documento planeacion", label: "Documento de Planeación" },
      { value: "documento calidad", label: "Documento de Calidad" },
      { value: "formatoCalidad", label: "Formato de Calidad" },
      { value: "indicadorCalidad", label: "Indicador de Calidad" },
      { value: "informe", label: "Informe" },
      { value: "orden de compra", label: "Orden de compra" },
    ];

    if (!auth.uid) return <Redirect to="/iniciarSesion" />;

    return (
      <div className="contenedor-nuevoDocumento">
        <div className="container">
          <div className="container">
            <div className="container">
              <form onSubmit={this.handleSubmit} className="white">
                <h5 className="grey-text text-darken-3">Nuevo Documento</h5>
                <div className="input-field">
                  <label htmlFor="nombre">Titulo del documento</label>
                  <input type="text" id="nombre" onChange={this.handleChange} />
                </div>
                <div className="input-field">
                  <label htmlFor="mes">Mes del documento</label>
                  <input type="text" id="mes" onChange={this.handleChange} />
                </div>
                <div className="input-field">
                  <label htmlFor="url">Url del documento</label>
                  <input type="text" id="url" onChange={this.handleChange} />
                </div>
                {/* <div className="input-field">
                <label htmlFor="area">Area perteneciente del documento</label>
                <input type="text" id="area" onChange={this.handleChange} />
              </div> */}
                <div className="input-field">
                  <CreatableSelect
                    defaultValue={{
                      value: 0,
                      label: "Selecciona un tipo de documento",
                    }}
                    isClearable
                    onChange={this.handleChangeSelectTipo}
                    id="tipo"
                    name="tipoDocumento"
                    options={optionsTipo}
                  />
                </div>
                <div className="input-field">
                  {/* <label htmlFor="proveedor">
                  Proveedor asociado al documento
                </label>
                <input
                  type="text"
                  id="proveedor"
                  onChange={this.handleChange}
                /> */}
                  <CreatableSelect
                    defaultValue={{
                      value: 0,
                      label: "Selecciona un proveedor",
                    }}
                    isClearable
                    onChange={this.handleChangeSelect}
                    name="proveedor"
                    id="proveedor"
                    options={options}
                  />
                </div>
                <div className="input-field">
                  <button className="btn cyan darken-2 z-depth-1">Crear</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const id = state.firebase.auth.uid;
  const usuarios = state.firestore.data.usuarios;
  const usuario = usuarios ? usuarios[id] : null;
  const usuariosData = state.firestore.ordered.usuarios;
  return {
    auth: state.firebase.auth,
    profile: usuario,
    usuarios: usuariosData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    crearDocumento: (documento) => dispatch(crearDocumento(documento)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "usuarios" }])
)(CrearDocumento);
