import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/navbar";
import home from "./components/pages/home";
import IniciarSesion from "./components/auth/iniciarSesion";
import Registro from "./components/auth/registro";
import CrearDocumento from "./components/documentos/nuevoDocumento";
import proveedorPlaneacion from "./components/pages/proveedorPlaneacion";
import proveedorCalidad from "./components/pages/proveedorNegociacion";
import calidadCS from "./components/pages/calidadCS";
import planeacionCS from "./components/pages/planeacionCS";
import proveedorTesoreria from "./components/pages/proveedorTesoreria";
import tratamientoDatos from "./components/empresa/politicaDatos";
import Footer from "./components/layout/footer";
import "./main.css"

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
          <Route path="/iniciarSesion" component={IniciarSesion} />
          <Route path="/registro" component={Registro} />
          <Route path="/nuevoDocumento" component={CrearDocumento} />
        <div id="contenedorCompleto">
          <Route exact path="/" component={home} />
          <Route path="/politica-de-tratamiento-de-datos" component={tratamientoDatos} />
          <Route path="/planeacion/:id" component={proveedorPlaneacion} />
          <Route path="/calidad/:id" component={proveedorCalidad} />
          <Route path="/calidadcs/:id" component={calidadCS} />
          <Route path="/planeacioncs/:id" component={planeacionCS} />
          <Route path="/financiera/:id" component={proveedorTesoreria} />
        </div>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
