import React from "react";

const ResumenDocumento = ({ documento }) => {
  return (
    <div className="col s12 m6 l4" style={{ margin: "0"}}>
      <a href={documento.url} target="_blank"  rel="noopener noreferrer">
        <div
          className="card-panel grey lighten-5 z-depth-2 hoverable tarjetaDocumentos"
          style={{ cursor: "pointer" }}
        >
          <div className="row valign-wrapper">
            <div className="col s5 m4">
              <img
                src="https://cdn.pixabay.com/photo/2017/11/10/05/24/upload-2935442_960_720.png"
                className="circle"
                height="70px"
                width="70px"
                alt=""
              />
            </div>
            <div className="col s7 m8 center">
              <span className="black-text" style={{ fontSize: "1.5rem" }}>
                Orden de compra {documento.mes}
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
    // <div className="project-list section">
    //   <div className="card z-depth-0 project-summary">
    //     <div className="card-body grey-text text-darken-3">
    //       <span className="card-title">{proyecto.titulo}</span>
    //       <p>{proyecto.descripcion}</p>
    //       <p>
    //         <b>
    //           Publicado por {proyecto.nombreAutor} {proyecto.apellidoAutor}
    //         </b>
    //       </p>
    //       <p className="grey-text">{proyecto.creadoEn}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ResumenDocumento;
