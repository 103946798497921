import React from "react";
import Logo from "../../images/logo.png";
import { connect } from "react-redux";
import "./footer.css";

const footer = (props) => {
  const { auth } = props;
  if (auth.uid) {
    return (
      <>
        <div className="todoFooter">
          <footer className="fixed-footer">
            <div className="contenidoFooter">
              <div>
                <h2>Derechos</h2>
                <p>
                  Todos los derechos reservados, Haceb Whirlpool Industrial
                  S.A.S.
                </p>
              </div>
              <div>
                <img src={Logo} alt="Logo HWI" />
              </div>
              <div>
                <h2>Contacto</h2>
                <ul>
                  <li>Carrera 48 N° 59-21</li>
                  <li>Copacabana -Antioquia</li>
                  <li>540 84 70</li>
                  <li>NIT 900666078-7</li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(footer);
