import React from "react";
import documentoimg from "../../images/documento.png";
import auditoriaimg from "../../images/auditoria.png";
import "./tarjetasDocumentos.css";

const TarjetasDocumento = ({ documentos, area, id }) => {
  return (
    <>
      {documentos &&
        documentos.map((documento) => {
          if (documento.area === area && documento.uid === id) {
            return (
              <div className="todo" key={documento.id}>
                <section className="secTarjetas-doc">
                  <div className="contenedor">
                    <div className="tarjeta">
                      <div
                        className="imgBx"
                        data-text={documento.tipoDocumento}
                      >
                        <img
                          src={
                            documento.tipoDocumento === "documento"
                              ? documentoimg
                              : auditoriaimg
                          }
                          alt="document"
                        />
                      </div>
                      <div className="content">
                        <div>
                          <h3>{documento.nombre}</h3>
                          <p>fecha: {documento.creadoEn}</p>
                          <a href={documento.url}>ir al documento</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default TarjetasDocumento;
