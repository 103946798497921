import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyBYsifUeGCEetSMi0WcI6KDJ_EuQVogBUs",
  authDomain: "gestionproyectos-1adf7.firebaseapp.com",
  databaseURL: "https://gestionproyectos-1adf7.firebaseio.com",
  projectId: "gestionproyectos-1adf7",
  storageBucket: "gestionproyectos-1adf7.appspot.com",
  messagingSenderId: "600440302124",
  appId: "1:600440302124:web:d8b3914586d827400bbf89"
};
firebase.initializeApp(config);
// firebase.firestore().settings({ timestampsInSnapshots: true });
const firestore = firebase.firestore();
const middleware = [thunk.withExtraArgument({ firestore })];

const store = createStore(rootReducer, compose(applyMiddleware(...middleware)));

const rrfConfig = {
  userProfile: "usuarios",
  useFirestoreForProfile: true,
  attachAuthIsReady: true
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
