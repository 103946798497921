import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import "./videoCubo.css";
import video from "../../videos/HWI ESP FINAL FINAL.mp4";

class videoCubo extends Component {
  constructor(props) {
    super(props);
    this.play = React.createRef();
    this.video = React.createRef();
    this.video1 = React.createRef();
    this.video2 = React.createRef();
    this.video3 = React.createRef();
  }

  componentDidMount() {
    if(this.props.history.location.pathname === '/'){
      window.onscroll = () => this.handleReproduccion();
    }else{
      return null
    }
  }

  handleReproduccion = () => {
    if(this.props.history.location.pathname === '/'){
    const seccion = this.play.current;
    const top = seccion.offsetTop;
    const video = this.video.current;
    const video1 = this.video1.current;
    const video2 = this.video2.current;
    const video3 = this.video3.current;
    const scroll = document.documentElement.scrollTop;
    const tope = top - document.documentElement.clientHeight + seccion.offsetHeight;
    // console.log(tope);
    if (scroll >= top - document.documentElement.clientHeight && scroll <= tope + 400) {
      // console.log(top - document.documentElement.clientHeight);
      video.play();
      video1.play();
      video2.play();
      video3.play();
    } else {
      video.pause();
      video1.pause();
      video2.pause();
      video3.pause();
    }}else{
      return null
    }
  };
  render() {
    return (
      <>
        <section className="section-video" ref={this.play}>
          <video
            className="video-repro"
            ref={this.video}
            src={video}
            muted
            loop
          ></video>
          <div className="caja">
            <div>
              <span>
                <video
                  className="video-repro"
                  ref={this.video1}
                  src={video}
                  muted
                  loop
                ></video>
              </span>

              <span>
                <video
                  className="video-repro"
                  ref={this.video2}
                  src={video}
                  muted
                  loop
                ></video>
              </span>

              <span>
                <video
                  className="video-repro"
                  ref={this.video3}
                  src={video}
                  muted
                  loop
                ></video>
              </span>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(videoCubo);
