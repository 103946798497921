import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import "./calidadCS.css";
import formatoimg from "../../images/formato1.png";
import documentosimg from "../../images/documentos.png";
import TarjetasInformes from "../documentos/tarjetasInformes";

class calidadCS extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 2000);
  }
  render() {
    const { usuario, auth, documentos } = this.props;
    if (!auth.uid) return <Redirect to="/iniciarSesion" />;

    if (usuario && usuario.area === "cadenaDeSuministro" && documentos) {
      return (
        <>
          <div className="contenedor-pagina">
            <Tabs>
              <div className="tabs">
                <Tab>
                  <button className="tab">Documentos</button>
                </Tab>
                <Tab>
                  <button className="tab">Formatos</button>
                </Tab>
                <Tab>
                  <button className="tab">Informe</button>
                </Tab>
                <Tab>
                  <button className="tab">Procedimientos</button>
                </Tab>
                <Tab>
                  <button className="tab">Indicadores</button>
                </Tab>
                <Tab>
                  <button className="tab">Formatos Específicos</button>
                </Tab>
              </div>

              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <div className="tarjeta">
                        <div
                          className="imgBx"
                          data-text="Manual de Proveedores"
                        >
                          <img src={documentosimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>Manual de Proveedores</h3>
                            <a
                              rel="noopener noreferrer"
                              href="https://drive.google.com/file/d/10LvM1pYk9JxH9U091bc1pm9Se4sE8JVw/view?usp=sharing"
                              target="_blank"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tarjeta">
                        <div className="imgBx" data-text="Acuerdo de Calidad">
                          <img src={documentosimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>Acuerdo General de Calidad</h3>
                            <a
                              rel="noopener noreferrer"
                              href="https://drive.google.com/file/d/1I2ZQ_35DQ7N6eJamIwwUvJ9syT2z7TzE/view?usp=sharing"
                              target="_blank"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <div className="tarjeta">
                        <div className="imgBx" data-text="formato QEWO">
                          <img src={formatoimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>QEWO</h3>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://drive.google.com/file/d/12eha_xXWI-T0f9ggyN4IHwCtQTfKRK2L/view?usp=sharing"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tarjeta">
                        <div className="imgBx" data-text="formato PPAP">
                          <img src={formatoimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>PPAP</h3>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://drive.google.com/file/d/1PEtMB5dIpkY2TzlNpTvgoC0fh3853AJp/view?usp=sharing"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tarjeta">
                        <div className="imgBx" data-text="Auditoría GSQA">
                          <img src={formatoimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>Auditoría GSQA</h3>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://drive.google.com/file/d/1BcFJnYYMdYdNico0rQslcqSDEao9VWWa/view?usp=sharing"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tarjeta">
                        <div className="imgBx" data-text="Embarque Controlado">
                          <img src={formatoimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>Embarque Controlado</h3>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://www.google.com.co"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <TarjetasInformes
                        documentos={documentos}
                        area={usuario.area}
                        id={auth.uid}
                        tipo="informe"
                      />
                    </div>
                  </section>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <div className="tarjeta">
                        <div className="imgBx" data-text="Desarrollo PPAPS">
                          <img src={formatoimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>Desarrollo PPAPS</h3>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://drive.google.com/file/d/1V_rkKrF8Bg5SbOR2XgCTcg-6sofoVAD8/view?usp=sharing"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tarjeta">
                        <div
                          className="imgBx"
                          data-text="Certificados en Línea"
                        >
                          <img src={formatoimg} alt="document" />
                        </div>
                        <div className="content">
                          <div>
                            <h3>Certificados en Línea</h3>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://drive.google.com/file/d/1EpKKCjWgwSqV1lMpd1k9f5JUqn6wbA7c/view?usp=sharing"
                            >
                              ir al documento
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <TarjetasInformes
                        documentos={documentos}
                        area={usuario.area}
                        id={auth.uid}
                        tipo="indicadorCalidad"
                      />
                    </div>
                  </section>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <TarjetasInformes
                        documentos={documentos}
                        area={usuario.area}
                        id={auth.uid}
                        tipo="formatoCalidad"
                      />
                    </div>
                  </section>
                </div>
              </Panel>
            </Tabs>
          </div>
        </>
      );
    } else {
      return <div>No se ha podido cargar</div>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const usuarios = state.firestore.data.usuarios;
  const documentos = state.firestore.ordered.documentos;
  const usuario = usuarios ? usuarios[id] : null;

  return {
    usuario: usuario,
    auth: state.firebase.auth,
    documentos: documentos,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "usuarios" }, { collection: "documentos" }])
)(calidadCS);
