import firebase from "firebase/app";
import "firebase/firestore";

export const crearDocumento = (documento) => {
  return (dispatch, getState, { getFirestore }) => {
    // hace una llamada asyncrona con la base de datos
    const firestore = firebase.firestore();
    const profile = getState().firebase.profile;
    const idCreador = getState().firebase.auth.uid;
    const opcionesFecha = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: 'numeric',
      minute: 'numeric',
    };
    firestore
      .collection("documentos")
      .add({
        ...documento,
        idCreador: idCreador,
        creadoEn: new Date().toLocaleDateString('us-NA', opcionesFecha),
        area: profile.area
      })
      .then(() => {
        dispatch({ type: "CREAR_DOCUMENTO", documento });
      })
      .catch((err) => {
        dispatch({ type: "<CREAR_DOCUMENTO></CREAR_DOCUMENTO>_ERROR", err });
      });
  };
};
