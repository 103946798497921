import React from "react";
import informeimg from "../../images/informe.png";
// import "./tarjetasDocumentos.css";

const TarjetasInformes = ({ documentos, area, id, tipo }) => {
  return (
    <>
      {documentos &&
        documentos.map((documento) => {
          if (
            documento.area === area &&
            documento.uid === id &&
            documento.tipoDocumento === tipo
          ) {
            return (
              // <div className="todo" key={documento.id}>

              <div className="tarjeta">
                <div className="imgBx" data-text={documento.nombre}>
                  <img src={informeimg} alt="informe" />
                </div>
                <div className="content">
                  <div>
                    <h3>{documento.nombre}</h3>
                    <p>fecha: {documento.creadoEn}</p>
                    <a
                      href={documento.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ir al documento
                    </a>
                  </div>
                </div>
              </div>
              // </div>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default TarjetasInformes;
