import firebase from "firebase/app";

export const LogIn = (credentials) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.correo, credentials.contraseña)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = (credentials) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        firebase.logout();
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.correo, newUser.contraseña)
      .then((res) => {
        return firestore
          .collection("usuarios")
          .doc(res.user.uid)
          .set({
            nombre: newUser.nombre,
            area: newUser.area,
            iniciales: newUser.nombre[0] + newUser.area[0],
            correo: newUser.correo,
            contraseña: newUser.contraseña,
            dataStudio: newUser.dataStudio,
            rol: newUser.rol
          });
      })
      .then(() => {
        dispatch({ type: "REGISTRO_COMPLETO" });
      })
      .catch((err) => {
        dispatch({ type: "REGISTRO_ERROR", err });
      });
  };
};
