import React from "react";
import "./valores.css";
// import diversidad from "../../images/diversidad.png";
import valores from "../../images/valores.jpg";

const Valores = () => {
  return (
    <>
      <section className="sectionValores">
        <div className="cajaImagen">
          <img src={valores} alt="VALORES HWI" />
        </div>
        <div className="contenido-sticky">
          <h2 id="integridad">Integridad</h2>
          <p>
            Somos personas <b>INTEGRAS</b> al ser coherentes con nuestros
            pensamientos y acciones, respetando las personas, las leyes y las
            costumbres, tomando las decisiones correctas para HWI siempre con
            información clara y transparente.
          </p>
          <h2 id="espiritu">Espíritu de equipo</h2>
          <p>
            En HWI tenemos <b>ESPÍRITU DE EQUIPO</b> al empoderar a las personas
            confiando siempre en sus aptitudes y actitudes, al escuchar con
            empatía y respeto al otro y al tener siempre como sello el sentido
            de pertenencia que hace que los resultados en equipo sean más
            importantes que los resultados individuales.
          </p>
          <h2 id="diversidad">Diversidad e inclusión</h2>
          <p>
            En HWI se valoran las ideas diferentes, se comprende antes de juzgar
            y se proporcionan ambientes de generación de ideas en donde el
            respeto, la equidad y la apertura al cambio con empatía son el pilar
            que llamamos <b>DIVERSIDAD E INCLUSIÓN</b>.
          </p>
          <h2 id="pasion">Pasión por los resultados</h2>
          <p>
            La <b>PASIÓN POR LOS RESULTADOS</b> es lo que moviliza a HWI, donde
            cada una de las personas que lo componen siempre están en pro de
            exceder las expectativas mediante su espíritu de victoria, lo cual
            permite que sean seres cada vez más innovadores y persistentes que
            buscan la excelencia en la ejecución para obtener resultados
            sostenibles.
          </p>
          <h2 id="flexibilidad">Flexibilidad con Responsabilidad</h2>
          <p>
            En HWI promovemos la flexibilidad y el equilibrio entre la vida
            personal y laboral, cada empleado es autónomo, se empodera del
            cumplimiento de sus resultados, tiene la capacidad de enfocar sus
            responsabilidades y gestionar con responsabilidad su tiempo siendo
            flexible y buscando ese balance personal y laboral.
          </p>
        </div>
      </section>
    </>
  );
};

export default Valores;
