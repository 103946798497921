import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import "./calidadCS.css";
import TarjetasInformes from "../documentos/tarjetasInformes";

class ProveedorPlaneacion extends Component {
  render() {
    const { usuario, auth, documentos } = this.props;

    if (!auth.uid) return <Redirect to="/iniciarSesion" />;

    if (usuario && usuario.area === "cadenaDeSuministro" && documentos) {
      const documento = documentos;

      return (
        <>
          <div className="contenedor-pagina">
            <Tabs>
              <div className="tabs">
                <Tab>
                  <button className="tab">Indicadores</button>
                </Tab>
                <Tab>
                  <button className="tab">Ordenes de compra</button>
                </Tab>
                <Tab>
                  <button className="tab">Documentos</button>
                </Tab>
              </div>

              <Panel>
                <div className="panel">
                  <div className="row" style={{ minHeight: "500px" }}>
                    <div className="col s12 m6 l6">
                      <iframe
                        id="frameIndicador"
                        title="dataStudio"
                        width="80%"
                        height="315"
                        src={usuario.dataStudio}
                        frameBorder="none"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        // style={{ marginTop: "50px" }}
                      />
                    </div>
                    <div className="col s12 m6 l6 center">
                      <h3 style={{ marginTop: "0", paddingRight: "20px" }}>
                        <span style={{ color: "teal", fontSize: "25px" }}>
                          Indicador de cumplimiento
                        </span>
                        <br />{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {usuario.nombre}
                        </span>{" "}
                        - HWI
                      </h3>
                      <div className="left-align" style={{ marginLeft: "15%" }}>
                        Dentro de este indicador puedes encontrar:
                        <ul>
                          <li>Niveles de servicio</li>
                          <li>Cumplimiento de entregas</li>
                          <li>Entregas pendientes</li>
                          <li>Niveles de satisfacción</li>
                        </ul>
                      </div>
                      <blockquote className="left-align linea-izquierda">
                        Recuerda que para ingresar al indicador debes acceder
                        con la cuenta de correo electrónico vinculada a la base
                        de datos de HWI.
                      </blockquote>
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <TarjetasInformes
                        documentos={documento}
                        area={usuario.area}
                        id={auth.uid}
                        tipo="orden de compra"
                      />
                    </div>
                  </section>
                </div>
              </Panel>
              <Panel>
                <div className="panel">
                  <section className="secTarjetas-doc">
                    <div className="contenedor">
                      <TarjetasInformes
                        documentos={documento}
                        area={usuario.area}
                        id={auth.uid}
                        tipo="documento"
                      />
                    </div>
                  </section>
                </div>
              </Panel>
            </Tabs>
          </div>
        </>
      );
    } else {
      return <div>página no encontrada</div>;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const usuarios = state.firestore.data.usuarios;
  const documentos = state.firestore.ordered.documentos;
  const usuario = usuarios ? usuarios[id] : null;

  return {
    usuario: usuario,
    auth: state.firebase.auth,
    documentos: documentos,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "usuarios" }, { collection: "documentos" }])
)(ProveedorPlaneacion);
