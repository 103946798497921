import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signUp } from "../../store/actions/authActions";
import CreatableSelect from "react-select/creatable";
import "./registro.css";

class Registro extends Component {
  state = {
    correo: "",
    contraseña: "",
    nombre: "",
    area: "",
    rol: "",
  };
  handleChange = (e) => {
    // console.log(this.state);
    // console.log(this.props);
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleChangeSelect = (newValue, actionMeta) => {
    if (actionMeta.action !== "clear") {
      this.setState({
        rol: newValue.value,
      });
    } else {
      this.setState({
        rol: "",
      });
    }
    console.log(this.state);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);
    this.props.signUp(this.state);
  };

  render() {
    const options = [
      {
        value: "admin",
        label: "Administrador",
      },
      {
        value: "proveedor",
        label: "Proveedor",
      },
    ];
    const { auth, authError } = this.props;
    if (auth.uid !== "qfVtcx4o8jNW0DJuL9DWGwvGFgd2") return <Redirect to="/" />;
    // console.log(this.props);
    return (
      <div className="contenedor-registro">
        <div className="container">
          <div className="container">
            <div className="container">
              <form onSubmit={this.handleSubmit} className="white">
                <h5 className="grey-text text-darken-3">Registro de empresa</h5>
                <div className="input-field">
                  <label htmlFor="nombre">Nombre Empresa</label>
                  <input
                    required
                    type="text"
                    id="nombre"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="correo">Correo</label>
                  <input
                    required
                    type="email"
                    id="correo"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="contraseña">Contraseña</label>
                  <input
                    required
                    type="password"
                    id="contraseña"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="area">Area responsable</label>
                  <input
                    required
                    type="text"
                    id="area"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="dataStudio">Url DataStudio</label>
                  <input
                    required
                    type="text"
                    id="dataStudio"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-field">
                  <CreatableSelect
                    defaultValue={{ value: 0, label: "Selecciona un rol" }}
                    isClearable
                    onChange={this.handleChangeSelect}
                    required
                    options={options}
                  />
                </div>
                <div className="input-field">
                  <button className="btn cyan darken-2 z-depth-1">
                    Registrar
                  </button>
                  <div className="red-text center">
                    {authError ? <p>{authError}</p> : null}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registro);
