import React, { Component } from "react";
import { Link } from "react-router-dom"

import "./navLateral.css";

class navLateral extends Component {
  state = {
    activado: ""
  };

  toggleLateral = () => {
    if (this.state.activado === "") {
      this.setState({
        activado: "lateralActive"
      });
    } else {
      this.setState({
        activado: ""
      });
    }
  };
  render() {
    return (
      <>
        <nav className="navLateral" id={this.state.activado}>
          <button
            className="tooggle"
            id="tooggle"
            onClick={this.toggleLateral}
          >
            <i className="material-icons iconAbrir">menu</i>
            <i className="material-icons iconCerrar">menu_open</i>
          </button>

          <ul>
            <li>
              <a href="#quienesSomos">¿Quiénes Somos?</a>
            </li>
            <li>
              <a href="#politicasCumplimiento">Políticas de Cumplimiento</a>
            </li>
            <li>
              <Link to="/politica-de-tratamiento-de-datos"> Política de Tratamiento de Datos </Link>
            </li>
            <li>
              <a href="#certificaciones">Certificaciones</a>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default navLateral;
