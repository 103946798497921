import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import SesionLinks from "./sesionLinks";
// import NoSesionLinks from "./noSesionLinks";
import { connect } from "react-redux";
import "./navBar.css";

const Navbar = (props) => {
  const { auth, profile } = props;
  // console.log(props);
  const links = auth.uid ? <SesionLinks profile={profile} /> : null;
  if (auth.uid) {
    return (
      <nav
        className="nav-wrapper navPrincipal" style={{ backgroundColor: "#131419" }}
      >
        <div style={{ width: "80%", marginLeft: "10%" }}>
          <Link to="/" className="brand-logo hide-on-med-and-down">
            <img
              src={Logo}
              height="55px"
              style={{ marginTop: "10px" }}
              alt="logo - HWI"
            />
          </Link>

          {auth.isLoaded && links}
        </div>
      </nav>
    );
  } else {
    return (
      <nav className="nav-wrapper navPrincipal" style={{ backgroundColor: "#131419" }}>
        <div style={{ width: "80%", marginLeft: "10%" }}>
          <Link to="/" className="brand-logo center">
            <img
              src={Logo}
              height="55px"
              style={{ marginTop: "10px" }}
              alt="logo - HWI"
            />
          </Link>

          {auth.isLoaded && links}
        </div>
      </nav>
    );
  }
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Navbar);
